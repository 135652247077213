@import url('https://fonts.googleapis.com/css?family=Tajawal');

body{
  margin: 0px;
}

* {
  font-family: 'Tajawal';
  padding: 0;
  margin: 0;
}

.dblue {
  color: #121E61;
}

.gold {
  color: #C3A058;
}

.gold-bold{
  color: #C3A058 !important;
  font-weight: bold !important;
}
.color-white{
  color:white;
}
.gray{
  color: #E6E6E6;
}

.map {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
}

.game{
  max-height: 365px;
  /* width: 235px; */
  max-width: 95%;
  /* margin: 0px 3px; */
  /* background-color: gray; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}

.gameImg{
  max-width: 95%;
  border-radius: 20px;
}

.gameImg:hover{
  opacity: 0.75;
}

.PCs {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
}

.slick-arrow {
  z-index: 99;
}

.slick-arrow.slick-prev::before{
  content: none;
}

.slick-arrow.slick-prev {
  background:url(./images/left.png) !important;
  height: 35px;
  width: 35px;
  opacity: 1;
  left: -10px;
}

.slick-arrow.slick-prev:hover{
  color: initial;
  outline: none;
  background: url(./images/left.png) !important;
  opacity: .75;
}

.slick-arrow.slick-next::before{
  content: none;
}

.slick-arrow.slick-next{
  background:url(./images/right.png) !important;
  height: 35px;
  width: 35px;
  opacity: 1;
  right: 0px;
}

.slick-arrow.slick-next:hover{
  color: initial;
  outline: none;
  background: url(./images/right.png) !important;
  opacity: .75;
}

.slick-disabled{
  opacity: .5 !important;
}

.slick-dots {
  position: inherit !important;
}

.swiper-pagination{
  position: fixed !important;
  bottom: -30px !important;
}

/* header start*/
.header{
  height: 600px;
  background: url(./images/Back.png);
  background-size: cover;
}

.heroText{
  color:'#E6E6E6';

}

/* header end*/

/* footer start*/
.footer{
  background-color: #1D1D1D;
  margin-top: 120px;
}
.footer-devider{
  width: 100%;
  border-top: 2px solid #E6E6E6;
}

/* footer end*/
.swiper-link {
  position: absolute;
  top: 90%;
  left: 52%;
  transform: translate(-50%, -50%);
  width: 150px;
  font-size: 22px;
  font-weight: bold;
}

.b{
  font-weight: bold;
}

.f1{
  font-size: 45px;
}

.f2{
  font-size: 28px;
}

.f3{
  font-size: 25px;
}

.f4{
  font-size: 20px;
}

.pc{
  height: 350px;
}

@media screen and (min-width: 1350px) {

  .pc{
    height: 420px;
  }

}

@media screen and (max-width: 600px) {

  .pc{
    height: auto;
    width: 100%;
  }

  /* .swiper-link {
    font-size: 12px;
    left: 56%;
    transform: translate(-50%, -70%);
    width: 88px;
  } */

  .f1{
    font-size: 35px;
  }
  
  .f2{
    font-size: 22px;
  }
  
  .f3{
    font-size: 25px;
  }
  
  .f4{
    font-size: 20px;
  }
  
  .line{
    width: 150px;
    height: 7px;
  }
}

/* reservation page start */
.slide-container {
  max-width: 95%;
  height: 250px;
  border: 2px solid #121E61;
  background: white;
  border-radius: 30px;
}
.slide-container:hover {
  border: 2px solid #fff;
  background: linear-gradient(to right bottom, #4009F1, #121E61);
}


.slide-container path{
  fill: url(#grad1);
}

.slide-container:hover path{
  fill: white !important;
}

.slide-container:hover .slide-title{
  color: #fff;
}

.slide-container:hover .slide-button{
  color: #fff !important;
  border: 2px solid #fff !important;
}

.slick-current .slide-container{
  border: 2px solid #fff;
  background: linear-gradient(to right bottom, #4009F1, #121E61);
}

.slick-current .slide-container path{
  fill: white !important;
}

.slick-current .slide-container .slide-title{
  color: #fff;
}

.slick-current .slide-container .slide-button{
  color: #fff !important;
  border: 2px solid #fff !important;
}

.slide-icon{
  /* color: #121E61; */
  margin-top:20px;
}

.slide-title{
  color:#121E61;
  font-weight:bold;
  margin-top:10px;
}

.slide-button{
  color:#121E61 !important;
  border-radius:10px !important;
  font-weight:bold !important;
  border: 2px solid #121E61 !important;
  margin-top:35px !important;
  font-size: 18px !important;
  transition: none !important;
  padding: 10px 15px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  text-decoration: none;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  -webkit-box-pack: center;
  vertical-align: middle;
  display: inline-block;
  
}
/* reservation page end */